import { useRouter } from 'next/router';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import Head from 'next/head';
import { Text } from '@components/atoms/text/text';
import { Button } from '@components/atoms/button/button';
import { SimpleTemplateContainer } from '@components/templates';

const Custom404 = () => {
  const router = useRouter();
  return (
    <>
      <Head>
        <title>AdventistGiving - 404</title>
      </Head>
      <SimpleTemplateContainer isContainer>
        <div className="w-full flex flex-col md:flex-row md:justify-between mb-10">
          <div className="w-full md:w-4/12">
            <Text as="h1" color="nad-blue" size="6XL" className="font-serif">
              404
            </Text>
            <Text color="nad-blue" size="L" className="font-serif">
              <FormattedMessage
                id="2YFyet"
                defaultMessage="We couldn’t find that page. You may have mistyped it or the page no longer exists."
                description="404 message."
              />
            </Text>
            <Button className="mt-6" onClick={() => router.push('/')}>
              <FormattedMessage
                id="41hbkW"
                defaultMessage="Go back home"
                description="Button on 404 page to direct users to homepage."
              />
            </Button>
          </div>
          <div className="w-full md:w-7/12">
            <div className="cover w-full relative md:aspect-[16/10] aspect-[18/21]">
              <Image
                fill={true}
                sizes="100vw"
                className="object-cover mt-8"
                src="/noprecache/img/404.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </SimpleTemplateContainer>
    </>
  );
};
export default Custom404;
